import React from "react";

function Boton(){
    return (
        <div>
			<a href="https://wa.me/50230656786?text=Hola,%20le%20comento%20que%20me%20interesó%20el%20producto%20:%20" className="float" target="_blank" rel="noreferrer">
				<i className="fa fa-whatsapp my-float"></i>
			</a>
		</div>
    )
}

export default Boton
